import { useToast } from '@aurecon-creative-technologies/styleguide'
import { createChat, createWinwiseOpportunityChat, updateChat, updateWinwiseOpportunityChat } from '../api/ChatService'
import { STREAM_SEPERATOR, TEMP_ROW_KEY } from '../config/config'
import { subAppRoute } from '../enums/AppRouteConstants'
import { ChatTypeEnum, ChatTypeToPath, ChatTypeStream } from '../enums/ChatTypeEnum'
import { IChatSession, INewCapabilities, IQuestion } from '../models/IQuestionModels'
import { useLanguages } from './useLanguages'
import { useRecoilState, useSetRecoilState } from 'recoil'
import {
  QuestionInput,
  ChatSession,
  QuestionFile,
  FocusOnInput,
  NomicSession,
  ShowExtendedInput,
  LoadingAnswer,
  ScrollChat,
  ShowPreparingChat,
  InShareChat,
  WinwiseSelectedQueries,
} from '../stores/AppStore'
import { getQuestionResponse, getQuestionStream } from '../api/QuestionService'
import { isJsonString } from '../helpers/json'
import { delay } from '../helpers/utils'
import { TFunction } from 'i18next'
import { useNomicToken } from './useNomicToken'
import { RatingEnum } from '../enums/RatingEnum'
import { useNavigate, useParams } from 'react-router-dom'
import { ICreateChatResponseModel, ICustomAppResponseModel } from '../models/api/ICreateChatModels'

const getStopMessage = (t: TFunction): string => t('popup_mess5')

const EMPTY_QUESTION = {
  success: false,
  answers: [],
  sources: [],
  rating: RatingEnum.NO_RATING,
  edited: false,
  feedback: null,
  feedbackType: null,
  updatedAt: null,
  createdAt: null,
  loading: true,
  focus: null,
  fileName: null,
  fileName1: null,
  fileName2: null,
  capabilities: [],
  isSubscribed: false,
  submitting: true,
}

let controller = new AbortController()

export const useSubmitButton = (
  chatType: number,
  fileUploadRef?: React.RefObject<HTMLInputElement>,
  imageUploadRef?: React.RefObject<HTMLInputElement>,
  createNewChatCallback?: (chat: ICreateChatResponseModel) => void,
) => {
  const [questionInput, setQuestionInput] = useRecoilState(QuestionInput)
  const [chatSession, setChatSession] = useRecoilState(ChatSession)
  const [questionFile, setQuestionFile] = useRecoilState(QuestionFile)
  const [inShareChat, setInShareChat] = useRecoilState(InShareChat)
  const [nomicSession] = useRecoilState(NomicSession)
  const setFocusOnInput = useSetRecoilState(FocusOnInput)
  const setShowExtendedInput = useSetRecoilState(ShowExtendedInput)
  const setLoadingAnswer = useSetRecoilState(LoadingAnswer)
  const setScrollChat = useSetRecoilState(ScrollChat)
  const setShowPreparingChat = useSetRecoilState(ShowPreparingChat)
  const [winwiseSelectedQueries, setWinwiseSelectedQueries] = useRecoilState(WinwiseSelectedQueries)
  const { addToast } = useToast()
  const { t } = useLanguages()
  const { opportunityId } = useParams()
  const navigate = useNavigate()

  const { nomicToken } = useNomicToken(chatType)
  const isWinwiseChat = chatType === ChatTypeEnum.WINWISE

  const prepareChat = async () => {
    let newChatSession: IChatSession

    // New chat
    if (!chatSession) {
      setShowPreparingChat(true)

      const requestBody = {
        firstQuestion: questionInput,
        type: chatType,
        linkId: isWinwiseChat ? opportunityId : nomicSession?.id,
        nomicToken,
        inShare: inShareChat,
        selectedQueries: winwiseSelectedQueries ?? [],
      }

      const responseChat = isWinwiseChat
        ? await createWinwiseOpportunityChat(requestBody)
        : await createChat(requestBody)

      if (!responseChat?.data) {
        setShowPreparingChat(false)
        addToast({
          type: 'error',
          title: t('popup_toast2'),
          message: t('popup_mess3'),
          timeout: 5000,
          timeLabel: t('popup_toast_timelabel'),
        })
        return null
      }

      newChatSession = {
        chatId: responseChat.data.id,
        questions: [],
        type: chatType,
      }
      createNewChatCallback?.(responseChat.data)
      setShowPreparingChat(false)
    } else {
      newChatSession = { ...chatSession }

      const requestBody = {
        chatId: chatSession.chatId,
        linkId: isWinwiseChat ? opportunityId : '',
      }

      const response = isWinwiseChat ? await updateWinwiseOpportunityChat(requestBody) : await updateChat(requestBody)

      if (!response?.data) {
        addToast({
          type: 'error',
          title: t('popup_toast2'),
          message: t('popup_mess4'),
          timeout: 5000,
          timeLabel: t('popup_toast_timelabel'),
        })
        return null
      }
    }

    return newChatSession
  }

  const handleResponse = async (
    newChatSession: IChatSession,
    newQuestion: Pick<IQuestion, 'chatId' | 'question'>,
    tempQuestion: IQuestion,
  ) => {
    const response = await getQuestionResponse(
      {
        ...newQuestion,
        type: chatType,
        file: questionFile,
        controller,
        linkId: isWinwiseChat ? opportunityId : nomicSession?.id,
      },
      t,
    )
    const stopMessage = getStopMessage(t)
    if (!response.error && response?.response) {
      setChatSession({
        ...newChatSession,
        questions: [...newChatSession.questions, ...response.response],
      })
    } else {
      if (response.error === stopMessage)
        addToast({
          type: 'info',
          title: t('popup_toast4'),
          message: t('popup_mess5'),
          timeout: 5000,
          timeLabel: t('popup_toast_timelabel'),
        })
      else
        addToast({
          type: 'error',
          title: t('popup_toast2'),
          message: t('popup_mess6'),
          timeout: 5000,
          timeLabel: t('popup_toast_timelabel'),
        })
      setChatSession({
        ...newChatSession,
        questions: [...newChatSession.questions, { ...tempQuestion, loading: false, submitting: false }],
      })
    }
    setWinwiseSelectedQueries([])
    setScrollChat((s) => s + 1)

    setFocusOnInput((value) => value + 1)
  }

  const handleStreamResponse = async (
    newChatSession: IChatSession,
    newQuestion: Pick<IQuestion, 'chatId' | 'question'>,
    tempQuestion: IQuestion,
  ) => {
    const responseStream = await getQuestionStream(
      {
        ...newQuestion,
        type: chatType,
        file: questionFile,
        controller,
        linkId: isWinwiseChat ? opportunityId : nomicSession?.id,
        nomicToken,
      },
      t,
    )

    if (responseStream.error || !responseStream.response?.body) {
      addToast({
        type: 'error',
        title: t('popup_toast2'),
        message: responseStream.error ?? t('popup_mess6'),
        timeout: 5000,
        timeLabel: t('popup_toast_timelabel'),
      })
      setChatSession({
        ...newChatSession,
        questions: [...newChatSession.questions, { ...tempQuestion, loading: false, submitting: false }],
      })
      setLoadingAnswer(false)
      return
    }

    const reader = responseStream.response.body.getReader()
    const decoder = new TextDecoder()
    let text = ''
    let responseQuestion: IQuestion | null = null

    // eslint-disable-next-line no-constant-condition
    while (true) {
      await delay(100)

      let value: undefined | Uint8Array
      let done = false

      try {
        const read = await reader.read()
        value = read.value
        done = read.done
      } catch (err) {
        setChatSession({
          ...newChatSession,
          questions: responseQuestion
            ? [
                ...newChatSession.questions,
                { ...responseQuestion, loading: false, submitting: false, finishReason: 'User abort' },
              ]
            : [...newChatSession.questions],
        })
        break
      }

      if (done || controller.signal.aborted) break

      const decodedChunk = decoder.decode(value, { stream: true })
      text += decodedChunk
      const chunks = text.split(STREAM_SEPERATOR).reverse()

      try {
        for (const chunk of chunks) {
          if (chunk === '' || !isJsonString(chunk)) continue

          const cleanChunk = chunk.replace(/\n/g, '\\n')
          responseQuestion = JSON.parse(cleanChunk)
          break
        }

        if (!responseQuestion) continue

        setChatSession({
          ...newChatSession,
          questions: [...newChatSession.questions, responseQuestion],
        })
        setScrollChat((s) => s + 1)
        setFocusOnInput((value) => value + 1)
      } catch (err) {
        console.error(err)
      }
    }
  }

  const getChatNavigationPath = (
    chatType: number,
    newChatSession: IChatSession,
    nomicSession: ICustomAppResponseModel | null,
    opportunityId: string,
  ) => {
    const chatTypeSubAppRoute = {
      [ChatTypeEnum.CUSTOM_RECALL_APP]: subAppRoute.CRA_CHAT,
    }

    const chatTypePath = {
      [ChatTypeEnum.WINWISE]: `/${ChatTypeToPath[chatType]}/${opportunityId}/${newChatSession.chatId}`,
      [ChatTypeEnum.CUSTOM_RECALL_APP]: `/${ChatTypeToPath[chatType]}/${nomicSession?.id}/${chatTypeSubAppRoute[chatType]}/${newChatSession.chatId}`,
    }

    const defaultPath = `/${ChatTypeToPath[chatType]}/${newChatSession.chatId}`

    return chatTypePath[chatType] ?? defaultPath
  }

  const navigateToChat = (
    chatType: number,
    newChatSession: IChatSession,
    nomicSession: ICustomAppResponseModel | null,
    opportunityId: string,
  ) => {
    const path = getChatNavigationPath(chatType, newChatSession, nomicSession, opportunityId)
    navigate(path, { replace: true })
  }

  const handleQuestionSubmit = async (capabilities?: INewCapabilities) => {
    if (isWinwiseChat && !questionInput.trim() && !winwiseSelectedQueries.length) return
    if (!questionInput.trim() && !capabilities?.questionInput.trim() && !isWinwiseChat) return

    setLoadingAnswer(true)
    controller = new AbortController()

    const tempChatSession = await prepareChat()
    if (!tempChatSession) {
      resetInput()
      return null
    }

    // clear previous related questions
    const newChatSession = {
      ...tempChatSession,
      questions: tempChatSession?.questions.map((item) => ({ ...item, relatedQuestions: [] })),
    }

    const newQuestion = {
      chatId: newChatSession.chatId,
      question: capabilities?.questionInput ? capabilities.questionInput.trim() : questionInput.trim(),
      capabilities: capabilities?.capabilities,
      relatedQuestions: [],
      selectedQueries: isWinwiseChat ? winwiseSelectedQueries : [],
    }

    const tempQuestion = {
      ...newQuestion,
      ...EMPTY_QUESTION,
      rowKey: TEMP_ROW_KEY,
      capabilities: capabilities?.capabilities ?? [],
      selectedQueries: isWinwiseChat ? winwiseSelectedQueries : [],
    }

    setShowExtendedInput(false)

    setChatSession({
      ...newChatSession,
      questions: [...newChatSession.questions, tempQuestion],
    })

    setScrollChat((s) => s + 1)
    navigateToChat(chatType, newChatSession, nomicSession, opportunityId ?? '')

    if (ChatTypeStream[chatType]) handleStreamResponse(newChatSession, newQuestion, tempQuestion)
    else handleResponse(newChatSession, newQuestion, tempQuestion)

    resetInput()
    return chatSession
  }

  const resetInput = () => {
    setQuestionFile(null)
    setQuestionInput('')
    setLoadingAnswer(false)
    setInShareChat(false)
    setScrollChat((s) => s + 1)

    if (fileUploadRef?.current) fileUploadRef.current.value = ''
    if (imageUploadRef?.current) imageUploadRef.current.value = ''
  }

  const stopGenerating = () => {
    console.log('Aborting...')
    controller.abort()
    resetInput()
  }

  return { handleQuestionSubmit, stopGenerating }
}
