import { FC } from 'react'
import { useSetRecoilState } from 'recoil'
import { Icon, Tooltip } from '@aurecon-creative-technologies/styleguide'

import { IQuestion } from '../models/IQuestionModels'
import { CurrentQuestion, ShowEditHistoryModal } from '../stores/AppStore'
import ChatQuestionAttachments from './ChatQuestionAttachments'
import { formatText } from '../helpers/formatText'

import Style from '../styles/ChatQuestion.module.sass'
import classNames from 'classnames'

interface IChatQuestionProps {
  question: IQuestion
  cssClass?: string
}

const ChatQuestion: FC<IChatQuestionProps> = (props) => {
  const setShowEditHistoryModal = useSetRecoilState(ShowEditHistoryModal)
  const setCurrentQuestion = useSetRecoilState(CurrentQuestion)
  const { question, cssClass } = props

  const handleAnswerHistory = () => {
    setCurrentQuestion(question)
    setShowEditHistoryModal(true)
  }

  return (
    <div className={classNames(Style.question, cssClass)}>
      <div className={Style.questionText}>
        <div className={Style.avatar}>
          <Icon type='account_circle' outlined />
        </div>
        <pre className={Style.text}>
          {formatText(question.question)}
          {question.edited && (
            <Tooltip show='Show edit history' cssClass={Style.editedIcon}>
              <Icon type='edit_note' outlined onClick={handleAnswerHistory} />
            </Tooltip>
          )}
        </pre>
      </div>
      <ChatQuestionAttachments question={question} />
    </div>
  )
}

export default ChatQuestion
