import { CustomAppsLoader, FullScreen } from '../stores/AppStore'
import { useRecoilRefresher_UNSTABLE, useRecoilValue, useRecoilValueLoadable } from 'recoil'
import {
  Button,
  Checkbox,
  IOption,
  Dropdown,
  IOverflowMenuItemProps,
  Icon,
  OverflowMenu,
  Pill,
  Table,
  TableCell,
  TableRow,
  useToast,
  Tooltip,
  Loader,
} from '@aurecon-creative-technologies/styleguide'
import { appInsightsInstance } from '../api/AppInsights'
import { FC, useEffect, useMemo, useState } from 'react'
import Page from '../components/Page'
import Style from '../styles/CustomRecallAppHome.module.sass'
import NomicModal from '../components/modals/NomicModal'
import { INomicModel } from '../models/INomicModel'
import { NomicDataSourceEnum } from '../enums/BambooPropertyEnum'
import {
  createCustomRecallApp,
  deleteDraftCustomRecallApp,
  deletePublishedCustomRecallApp,
  publishCustomRecallApp,
} from '../api/CustomRecallAppService'
import { ICustomAppResponseModel, ICustomAppShareResponseModel } from '../models/api/ICreateChatModels'
import LoadingScreen from '../components/LoadingScreen'
import { CRATypeColor, CRATypeEnum, CRATypeLabel } from '../enums/CRATypeEnum'
import { format, parseISO } from 'date-fns'
import { sortArrBy } from '../helpers/utils'
import { ResponseData } from '../models/api/IResponse'
import ConfirmModal from '../components/modals/ConfirmModal'
import { ChatTypeEnum } from '../enums/ChatTypeEnum'
import { AppRoute } from '../enums/AppRouteConstants'
import ManageAccessModal from '../components/modals/ManageAccessModal'
import {
  createCustomRecallAppShare,
  getAllCustomAppShares,
  removeCustomRecallAppShare,
} from '../api/CustomRecallAppShareService'
import { twelveHourFormat } from '../config/config'
import { useNomicToken } from '../hooks/useNomicToken'
import NotificationModal from '../components/modals/NotificationModal'
import { useLanguages } from '../hooks/useLanguages'
import { Trans } from 'react-i18next'
import { TFunction } from 'i18next'
import SharePointModal from '../components/modals/SharePointModal'
import CreateNomicOrgModal from '../components/modals/CreateNomicOrgModal'
import { FeatureFlagEnum } from '../enums/FeatureFlagEnum'
import { useShowFeature } from '../hooks/useShowFeature'
import { IGenericModel } from '../models/api/IGenericModel'

export const DefaultNomicProperty: INomicModel = {
  id: '',
  dataSource: NomicDataSourceEnum.EXISTING_NOMIC,
  appName: '',
  nomicMap: '',
}

const ContextMenuItemIds = {
  PUBLISH: '1',
  DELETE: '2',
  OPEN: '3',
  MANAGE_ACCESS: '4',
  EDIT: '5',
}

const defaultSortOrder: ICraTableSortOrder = {
  name: 'none',
  mapUrl: 'none',
  status: 'none',
  updatedAt: 'desc',
}

type sortType = 'none' | 'desc' | 'asc' | undefined

interface ICraTableSortOrder {
  name: sortType
  mapUrl: sortType
  status: sortType
  updatedAt: sortType
}

const columnWidth = {
  name: '40%',
  url: '16%',
  status: '19%',
  dateAdded: '20%',
  action: '5%',
}

type pillType = 1 | 2 | 3 | 4

const SelectedMultipleItemsRender: React.FC<{ t: TFunction }> = ({ t }) => (
  <>
    <Icon type='filter_alt' size='16px' cssClass='filterIcon' className={Style.filterIcon} />
    {t('filter')}
  </>
)

const renderSelectedMultipleItems = (t: TFunction) => <SelectedMultipleItemsRender t={t} />

const getCursorStyle = (status: (typeof CRATypeEnum)[keyof typeof CRATypeEnum]) =>
  status === CRATypeEnum.PUBLISHED || status === CRATypeEnum.SHAREDWITHME ? 'pointer' : 'default'

const CustomRecallAppHome: FC = () => {
  const appInsights = appInsightsInstance()
  if (appInsights) appInsights.trackPageView({ name: 'Custom Recall App Home' })
  const [editNomicProperty, setEditNomicProperty] = useState<INomicModel | null>(null)
  const [customAppList, setCustomAppList] = useState<ICustomAppResponseModel[]>()
  const [customAppShareList, setCustomAppShareList] = useState<ICustomAppShareResponseModel[] | null>(null)
  const customAppsLoader = useRecoilValueLoadable(CustomAppsLoader)
  const refreshCustomApps = useRecoilRefresher_UNSTABLE(CustomAppsLoader)
  const [loadingRes, setLoadingRes] = useState(false)
  const [openManageAccessCra, setOpenManageAccessCra] = useState<ICustomAppResponseModel | null>(null)
  const [sortOrder, setSortOrder] = useState<ICraTableSortOrder>(defaultSortOrder)
  const [craToBePublished, setCraToBePublished] = useState<ICustomAppResponseModel | null>(null)
  const [acceptTc, setAcceptTc] = useState(false)
  const [deletingCra, setDeletingCra] = useState<string | null>(null)
  const { addToast } = useToast()
  const [filters, setFilters] = useState<string[]>(['myApp', 'sharedWithMe'])
  const [openErrorModalMessage, setOpenErrorModalMessage] = useState<string | JSX.Element | null>(null)
  const { loadingNomicToken, nomicToken } = useNomicToken(ChatTypeEnum.CUSTOM_RECALL_APP)
  const fullScreen = useRecoilValue(FullScreen)
  const [sharePointModalActive, setSharePointModalActive] = useState<boolean>(false)
  const [nomicOrgModalActive, setNomicOrgModalActive] = useState<boolean>(false)
  const showSharepointPicker = useShowFeature(FeatureFlagEnum.ShowSharePointFilePicker)

  const { t } = useLanguages()

  const sharedContextMenu: IOverflowMenuItemProps[] = [{ id: ContextMenuItemIds.OPEN, label: t('open') }]

  const draftContextMenu: IOverflowMenuItemProps[] = [
    { id: ContextMenuItemIds.EDIT, label: 'Edit' },
    { id: ContextMenuItemIds.PUBLISH, label: t('publish') },
    { id: ContextMenuItemIds.DELETE, label: t('delete') },
  ]

  const contextMenu = (shareCount: number): IOverflowMenuItemProps[] => [
    { id: ContextMenuItemIds.OPEN, label: t('open') },
    { id: ContextMenuItemIds.EDIT, label: 'Edit' },
    {
      id: ContextMenuItemIds.MANAGE_ACCESS,
      label: shareCount ? `${t('manage_access')} (${shareCount})` : t('manage_access'),
    },
    { id: ContextMenuItemIds.DELETE, label: t('delete') },
  ]

  const filterItems = [
    { id: 'myApp', label: t('my_app') },
    { id: 'sharedWithMe', label: t('share_app') },
  ]

  useEffect(() => {
    if (customAppsLoader.state !== 'hasValue' || !customAppsLoader.contents) return
    setCustomAppList(customAppsLoader.contents)
  }, [customAppsLoader.contents, customAppsLoader.state])

  const filteredAndSortedList = useMemo(() => {
    let filteredApps = customAppList || []
    if (!filters.includes('toggleAll')) {
      filteredApps = filteredApps.filter((app) => {
        if (filters.includes('myApp') && (app.status === CRATypeEnum.DRAFT || app.status === CRATypeEnum.PUBLISHED)) {
          return true
        }
        if (filters.includes('sharedWithMe') && app.status === CRATypeEnum.SHAREDWITHME) {
          return true
        }
        return false
      })
    }

    const foundSortKey = (Object.keys(sortOrder) as [keyof ICraTableSortOrder]).find(
      (key) => sortOrder[key] === 'asc' || sortOrder[key] === 'desc',
    )

    const sortKey = foundSortKey ?? 'updatedAt'
    const order = sortOrder[sortKey]

    return sortArrBy(order as string, filteredApps, sortKey) as ICustomAppResponseModel[]
  }, [customAppList, filters, sortOrder])

  const validateAndSave = async (data: INomicModel) => {
    setLoadingRes(true)

    if (!nomicToken) {
      addToast({
        type: 'error',
        title: t('popup_toast2'),
        message: t('cra_mess7'),
        timeout: 5000,
        timeLabel: t('popup_toast_timelabel'),
      })
      setLoadingRes(false)
      return
    }

    const res = await createCustomRecallApp({ ...data, nomicToken })

    if (!res?.data) {
      const errorMessage: string | JSX.Element =
        res?.status === 403 ? (
          <Trans
            i18nKey='nomic_map_not_assigned'
            components={[
              <a key={0} className='notify-url' href='https://aurecon-atlas.nomic.ai/' target='_blank' rel='noreferrer'>
                Nomic
              </a>,
            ]}
          />
        ) : (
          res?.message ?? t('unknown_error')
        )

      setOpenErrorModalMessage(errorMessage)
      setLoadingRes(false)
      return
    }

    addToast({
      type: 'success',
      title: t('popup_toast'),
      message: t('cra_mess'),
      timeout: 5000,
      timeLabel: t('popup_toast_timelabel'),
    })
    setLoadingRes(false)
    refreshCustomApps()
    if (!data.id) setCraToBePublished(res.data)
  }

  const onSort = (field: string, sort: string) => {
    const newOrder = {
      ...defaultSortOrder,
      updatedAt: sort === 'none' && field !== 'updatedAt' ? 'desc' : ('none' as sortType),
      [field]: sort,
    }
    setSortOrder(newOrder)
  }

  const publishCustomApp = async (id: string) => {
    setLoadingRes(true)

    const res = ResponseData(await publishCustomRecallApp({ id }))

    setLoadingRes(false)
    onPublishModalClose()

    if (!res) {
      addToast({
        type: 'error',
        title: t('popup_toast2'),
        message: t('cra_mess8'),
        timeout: 5000,
        timeLabel: t('popup_toast_timelabel'),
      })
      return
    }

    addToast({
      type: 'success',
      title: t('popup_toast'),
      message: t('cra_mess9'),
      timeout: 5000,
      timeLabel: t('popup_toast_timelabel'),
    })
    refreshCustomApps()
  }

  const getAndRefreshCustomAppShares = async (craId: string) => {
    const response = ResponseData(await getAllCustomAppShares({ id: craId }))
    setCustomAppShareList(response || [])
  }

  const deleteCraDraft = async (cra: ICustomAppResponseModel) => {
    setDeletingCra(cra.id)
    const res = ResponseData(await deleteDraftCustomRecallApp({ id: cra.id }))

    if (!res) {
      addToast({
        type: 'error',
        title: t('popup_toast2'),
        message: t('delete_app2', { appName: cra.name }),
        timeout: 5000,
        timeLabel: t('popup_toast_timelabel'),
      })
      setDeletingCra(null)

      return
    }

    addToast({
      type: 'success',
      title: t('popup_toast'),
      message: t('delete_app', { appName: cra.name }),
      timeout: 5000,
      timeLabel: t('popup_toast_timelabel'),
    })
    refreshCustomApps()
    setDeletingCra(null)
  }

  const deleteCraPublished = async (cra: ICustomAppResponseModel) => {
    setDeletingCra(cra.id)
    const res = ResponseData(await deletePublishedCustomRecallApp({ id: cra.id, nomicToken }))

    if (!res) {
      addToast({
        type: 'error',
        title: t('popup_toast2'),
        message: t('delete_app2', { appName: cra.name }),
        timeout: 5000,
        timeLabel: t('popup_toast_timelabel'),
      })
      setDeletingCra(null)

      return
    }

    addToast({
      type: 'success',
      title: t('popup_toast'),
      message: t('delete_app', { appName: cra.name }),
      timeout: 5000,
      timeLabel: t('popup_toast_timelabel'),
    })
    refreshCustomApps()
    setDeletingCra(null)
  }

  const deleteCra = async (cra: ICustomAppResponseModel) => {
    if (cra.status === CRATypeEnum.DRAFT) await deleteCraDraft(cra)
    else if (cra.status === CRATypeEnum.PUBLISHED) await deleteCraPublished(cra)
  }

  const onContextMenuItemSelect = async (item: IOverflowMenuItemProps, cra: ICustomAppResponseModel) => {
    switch (item.id) {
      case ContextMenuItemIds.PUBLISH:
        setCraToBePublished(cra)
        break
      case ContextMenuItemIds.OPEN:
        location.href = `#/${AppRoute.CUSTOM_RECALL_APP_CHAT}/${cra.id}`
        break
      case ContextMenuItemIds.MANAGE_ACCESS: {
        await getAndRefreshCustomAppShares(cra.id)
        setOpenManageAccessCra(cra)
        break
      }
      case ContextMenuItemIds.DELETE: {
        await deleteCra(cra)
        break
      }
      case ContextMenuItemIds.EDIT:
        setEditNomicProperty({
          id: cra.id,
          dataSource: NomicDataSourceEnum.EXISTING_NOMIC,
          appName: cra.name,
          nomicMap: cra.mapUrl,
        })
        break
    }
  }

  const onPublishModalClose = () => {
    setCraToBePublished(null)
    setAcceptTc(false)
  }

  const shareCra = async (users: IOption[], app: IGenericModel) => {
    const customApp = app as ICustomAppResponseModel
    if (!customApp.id || !users.length) return

    const usersData = users.map((u) => ({
      auth0UserId: u.id as string,
      email: u.value,
      name: u.display?.toString().split('(')[0].trim() || '',
    }))

    setLoadingRes(true)

    if (!nomicToken) {
      addToast({
        type: 'error',
        title: t('popup_toast2'),
        message: t('cra_mess10'),
        timeout: 5000,
        timeLabel: t('popup_toast_timelabel'),
      })
      setLoadingRes(false)
      return
    }

    const res = await createCustomRecallAppShare({ id: customApp.id, usersData, nomicToken })

    if (res?.status === 403) {
      const message = (
        <>
          You are unable to Share this Custom Recall App {customApp.name} as you are <br />
          not an administrator of the Nomic Atlas Map. Please speak to the <br />
          administrator of the map{' '}
          <a className='notify-url' href={customApp.mapUrl} target='_blank' rel='noreferrer'>
            Nomic map
          </a>
        </>
      )
      setLoadingRes(false)
      onManageAccessClose()
      setOpenErrorModalMessage(message)

      return
    }

    const data = res?.data

    if (!data) {
      addToast({
        type: 'error',
        title: t('popup_toast2'),
        message: t('cra_mess11'),
        timeout: 5000,
        timeLabel: t('popup_toast_timelabel'),
      })
      setLoadingRes(false)
      return
    }

    const createdUsers: string[] = []
    const notCreatedUsers: string[] = []

    users.forEach((user) => {
      const found = data.some((d) => d.auth0UserId === user.id)
      const name = (user.display as string).split('(')[0].trim()
      found ? createdUsers.push(name || '') : notCreatedUsers.push(name)
    })

    const createdMessageKey = createdUsers.length > 1 ? 'share_mess_plural' : 'share_mess'
    const createdMessage = createdUsers.length
      ? t(createdMessageKey, {
          userNames: createdUsers.join(', '),
          userName: createdUsers[0],
        })
      : ''

    const notCreatedMessageKey = notCreatedUsers.length > 1 ? 'cra_mess14_plural' : 'cra_mess14'
    const notCreatedMessage = notCreatedUsers.length
      ? t(notCreatedMessageKey, {
          userNames: notCreatedUsers.join(', '),
          userName: notCreatedUsers[0],
        })
      : ''

    if (createdMessage && !notCreatedMessage)
      addToast({
        type: 'success',
        title: t('popup_toast'),
        message: createdMessage,
        timeout: 5000,
        timeLabel: t('popup_toast_timelabel'),
      })

    if (createdMessage && notCreatedMessage)
      addToast({
        type: 'warning',
        title: t('popup_toast3'),
        message: createdMessage + ' ' + notCreatedMessage,
        timeout: 5000,
        timeLabel: t('popup_toast_timelabel'),
      })

    if (!createdMessage && notCreatedMessage)
      addToast({
        type: 'warning',
        title: t('popup_toast3'),
        message: notCreatedMessage,
        timeout: 5000,
        timeLabel: t('popup_toast_timelabel'),
      })

    setLoadingRes(false)
    await getAndRefreshCustomAppShares(customApp.id)
    refreshCustomApps()
  }

  const removeCra = async (customAppId: string, userId: string, name: string) => {
    if (!nomicToken) return
    const removeRes = ResponseData(await removeCustomRecallAppShare({ id: customAppId, userId, nomicToken }))

    if (!removeRes) {
      addToast({
        type: 'error',
        title: t('popup_toast2'),
        message: t('revoke_mess2', { userName: name }),
        timeout: 5000,
        timeLabel: t('popup_toast_timelabel'),
      })

      return
    }

    addToast({
      type: 'success',
      title: t('popup_toast'),
      message: t('revoke_mess', { userName: name }),
      timeout: 5000,
      timeLabel: t('popup_toast_timelabel'),
    })

    getAndRefreshCustomAppShares(customAppId)
    refreshCustomApps()
  }

  const onManageAccessClose = () => {
    setOpenManageAccessCra(null)
    setCustomAppShareList(null)
  }

  const publishModalRender = () => {
    const preText = t('publish_statement1')
    const endText = t('publish_statement2')
    return (
      <div className={Style.publishModalContent}>
        <div>
          {preText}
          <a className={Style.nomicUrl} href={craToBePublished?.mapUrl} target='_blank' rel='noreferrer'>
            Nomic
          </a>
          {endText}
        </div>
        <div className={Style.terms}>
          <Checkbox checked={acceptTc} onChange={setAcceptTc} /> {t('publish_accept')}
        </div>
      </div>
    )
  }

  const renderBrandNewCustomApp = () => {
    return (
      <>
        <h1 className={Style.headerTitle}>Custom Recall App</h1>
        <div className={Style.headerText}>
          <p>{t('cra_desc')}</p>
          <p>{t('start_create')}</p>
        </div>
        <div className={Style.craFooter}>
          <Button
            label={t('create')}
            cssClass={Style.craButton}
            onClick={() => setEditNomicProperty(DefaultNomicProperty)}
          />
        </div>
      </>
    )
  }

  const onFilterChange = (items: (string | number)[]) => {
    const selectedItems = items.map((item) => String(item))

    const newFilters = selectedItems.includes('toggleAll') ? ['toggleAll', 'myApp', 'sharedWithMe'] : selectedItems

    setFilters(newFilters)
  }

  const handleAppRowClick = (cra: ICustomAppResponseModel) => {
    if (isInSharedOrPublished(cra)) {
      location.href = `#/${AppRoute.CUSTOM_RECALL_APP_CHAT}/${cra.id}`
    }
    setCraToBePublished(cra)
  }

  const isInSharedOrPublished = (cra: ICustomAppResponseModel) =>
    cra.status === CRATypeEnum.PUBLISHED || cra.status === CRATypeEnum.SHAREDWITHME

  const getStatusColor = (status: number) => {
    let color = CRATypeColor[CRATypeEnum.DRAFT]
    if (status === CRATypeEnum.PUBLISHED) color = CRATypeColor[CRATypeEnum.PUBLISHED]
    if (status === CRATypeEnum.SHAREDWITHME) color = CRATypeColor[CRATypeEnum.SHAREDWITHME]

    return color as pillType
  }

  const handleManageAccessClick = async (e: React.MouseEvent, cra: ICustomAppResponseModel) => {
    e.stopPropagation()
    await getAndRefreshCustomAppShares(cra.id)
    setOpenManageAccessCra(cra)
  }

  const renderContextMenuOptions = (status: number, shareCount: number) => {
    let menu: IOverflowMenuItemProps[] = []
    switch (status) {
      case CRATypeEnum.DRAFT:
        menu = draftContextMenu
        break
      case CRATypeEnum.PUBLISHED:
        menu = contextMenu(shareCount)
        break
      case CRATypeEnum.SHAREDWITHME:
        menu = sharedContextMenu
        break
    }
    return menu
  }

  const openSharePointModal = () => {
    if (!showSharepointPicker.enabled) return
    setSharePointModalActive(true)
  }

  const onSharePointNextClicked = () => {
    setNomicOrgModalActive(true)
  }

  const renderAppList = () => {
    if (!filteredAndSortedList) return null
    return (
      <>
        <div className={Style.header}>
          <h2>Custom Recall App</h2>
          <p className={Style.headerSubText}>{t('cra_desc')}</p>
          <div>
            <Button
              label={t('create')}
              cssClass={Style.createTopButton}
              onClick={() => setEditNomicProperty(DefaultNomicProperty)}
            />
          </div>
        </div>
        <hr className={Style.divider} />
        <div className={Style.filterContainer}>
          <Dropdown
            cssClass='craFilterDropdown'
            multiple
            toggleAllItem={t('toggle_all')}
            items={filterItems}
            onSelectMultipleItems={onFilterChange}
            selectedMultipleItems={filters}
            selectedMultipleItemsRender={() => renderSelectedMultipleItems(t)}
          />
        </div>
        <Table
          headers={[
            {
              label: t('cra_name'),
              sort: sortOrder.name,
              onSort: (sort) => onSort('name', sort),
              style: { width: columnWidth.name },
            },
            {
              label: t('nomic_map'),
              style: { width: columnWidth.url },
            },
            {
              label: t('status'),
              sort: sortOrder.status,
              onSort: (sort) => onSort('status', sort),
              style: { width: columnWidth.status },
            },
            {
              label: t('dated_added'),
              sort: sortOrder.updatedAt,
              onSort: (sort) => onSort('updatedAt', sort),
              style: { width: columnWidth.dateAdded },
            },
            { label: t('action'), style: { width: columnWidth.action } },
          ]}
          cssClass={Style.craTable}
        >
          {filteredAndSortedList.map((c) => (
            <TableRow
              key={c.id}
              rowClass={Style.craTableRow}
              style={{
                cursor: getCursorStyle(c.status),
              }}
            >
              <TableCell
                title={c.name}
                cellClass={Style.cell}
                style={{
                  width: columnWidth.name,
                  cursor: getCursorStyle(c.status),
                }}
                onClick={() => handleAppRowClick(c)}
              >
                {c.status === CRATypeEnum.SHAREDWITHME && <Icon type='group' style={{ marginRight: '8px' }} />}
                {c.name}
              </TableCell>
              <TableCell cellClass={Style.cell} style={{ width: columnWidth.url }} onClick={() => handleAppRowClick(c)}>
                <Tooltip show={c.mapUrl} cssClass={Style.tooltipMapUrl}>
                  <a
                    href={c.mapUrl}
                    target='_blank'
                    rel='noopener noreferrer'
                    className={Style.nomicMapLink}
                    onClick={(e) => e.stopPropagation()}
                  >
                    Nomic Map
                  </a>
                </Tooltip>
              </TableCell>
              <TableCell
                cellClass={Style.cell}
                style={{
                  width: columnWidth.status,
                  cursor: getCursorStyle(c.status),
                }}
                onClick={() => handleAppRowClick(c)}
              >
                <Pill
                  size={fullScreen ? 'medium' : 'small'}
                  colour={getStatusColor(c.status)}
                  cssClass={`cra-pill-status-${c.status}`}
                >
                  {CRATypeLabel[c.status]}
                </Pill>
                {!!c.shareCount && (
                  <Tooltip show={t('manage_access')}>
                    <div
                      style={{ display: 'inline-block', cursor: 'pointer' }}
                      onClick={(e) => handleManageAccessClick(e, c)}
                      role='none'
                    >
                      <Icon type='group' style={{ marginLeft: '8px', cursor: 'pointer' }} />
                    </div>
                  </Tooltip>
                )}
              </TableCell>
              <TableCell
                cellClass={Style.date}
                style={{
                  width: columnWidth.dateAdded,
                  cursor: getCursorStyle(c.status),
                }}
                onClick={() => handleAppRowClick(c)}
              >
                {format(parseISO(c.updatedAt), twelveHourFormat)}
              </TableCell>
              <TableCell cellClass={Style.action} style={{ width: columnWidth.action }}>
                <div role='none' className={Style.actionWrapper}>
                  {deletingCra === c.id ? (
                    <Loader size='extra small' />
                  ) : (
                    <OverflowMenu
                      items={renderContextMenuOptions(c.status, c.shareCount)}
                      size='extra small'
                      icon='more_vert'
                      default
                      cssClass='cra-overflow-menu'
                      onSelectItem={(item) => onContextMenuItemSelect(item, c)}
                    />
                  )}
                </div>
              </TableCell>
            </TableRow>
          ))}
        </Table>
      </>
    )
  }

  const renderBody = () => {
    return (
      <>
        {!customAppList?.length ? renderBrandNewCustomApp() : renderAppList()}
        {!!editNomicProperty && (
          <NomicModal
            open={!!editNomicProperty}
            nomicPropertyModel={editNomicProperty}
            onSave={validateAndSave}
            onClose={() => setEditNomicProperty(null)}
            loading={loadingRes}
            openSharePointModal={openSharePointModal}
          />
        )}
        {craToBePublished && (
          <ConfirmModal
            open={!!craToBePublished}
            title={t('publish')}
            message={publishModalRender()}
            labelYes={t('publish')}
            labelClose={t('save_draft')}
            loadingYes={loadingRes}
            disabled={!acceptTc}
            size='small'
            onClose={onPublishModalClose}
            onSave={() => publishCustomApp(craToBePublished.id)}
            chatType={ChatTypeEnum.CUSTOM_RECALL_APP}
          />
        )}
        {openManageAccessCra && customAppShareList && (
          <ManageAccessModal
            open={!!openManageAccessCra}
            onSave={shareCra}
            onRevoke={removeCra}
            pickedItem={openManageAccessCra}
            shareList={customAppShareList}
            loading={loadingRes}
            onClose={onManageAccessClose}
            chatType={ChatTypeEnum.CUSTOM_RECALL_APP}
            description={t('manage_access_desc', { appName: openManageAccessCra.name })}
          />
        )}
        <NotificationModal
          open={!!openErrorModalMessage}
          onClose={() => setOpenErrorModalMessage(null)}
          title={'Error'}
          description={openErrorModalMessage || ''}
        />
        {!!editNomicProperty && showSharepointPicker.enabled && (
          <SharePointModal
            open={!!editNomicProperty && showSharepointPicker.enabled && sharePointModalActive}
            onClose={() => setSharePointModalActive(false)}
            onNext={onSharePointNextClicked}
          />
        )}
        <CreateNomicOrgModal open={nomicOrgModalActive} onClose={() => setNomicOrgModalActive(false)} />
      </>
    )
  }

  return (
    <Page menu contentWrapper>
      {!customAppList || loadingNomicToken ? <LoadingScreen text={t('loading_cra2')} /> : renderBody()}
    </Page>
  )
}

export default CustomRecallAppHome
