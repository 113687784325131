export const WinwiseOpportunityDocumentTypeEnum = {
  RFP_DOCUMENT: 1,
  OTHER_CLIENT_DOCUMENTS: 2,
  REFERENCE_MATERIAL: 3,
  RESPONSE_DRAFT: 4,
  HANDY_LINKS: 5,
}

export const WinwiseOpportunityUploadFileStatusEnum = {
  LOADING: 1,
  SUCCESS: 2,
  FAIL: 3,
}

export const WinwiseOpportunityDocumentOptions = [
  {
    id: WinwiseOpportunityDocumentTypeEnum.RFP_DOCUMENT,
    label: 'RFP Document',
  },
  {
    id: WinwiseOpportunityDocumentTypeEnum.OTHER_CLIENT_DOCUMENTS,
    label: 'Other Client Documents',
  },
  {
    id: WinwiseOpportunityDocumentTypeEnum.REFERENCE_MATERIAL,
    label: 'Reference Material',
  },
  {
    id: WinwiseOpportunityDocumentTypeEnum.RESPONSE_DRAFT,
    label: 'Response Draft',
  },
  {
    id: WinwiseOpportunityDocumentTypeEnum.HANDY_LINKS,
    label: 'Handy Links',
  },
]
