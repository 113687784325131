import { appInsightsInstance } from '../api/AppInsights'
import { FC, useCallback, useEffect, useState } from 'react'
import Page from '../components/Page'
import { useNavigate, useParams } from 'react-router-dom'
import Style from '../styles/WinwiseOpportunity.module.sass'
import CreateNewThreadModal from '../components/modals/CreateNewThreadModal'
import { getWinwiseOpportunityDetail } from '../api/winwiseService'
import { IWinwiseOpportunityDetailModel, IWinwiseOpportunityUploadedFile } from '../models/IWinwiseOpportunity'
import LoadingScreen from '../components/LoadingScreen'
import { AppRoute } from '../enums/AppRouteConstants'
import WinwiseOpportunityThread from '../components/winwise/WinwiseOpportunityThread'
import WinwiseOpportunityFileUpload from '../components/winwise/WinwiseOpportunityFileUpload'
import classnames from 'classnames'
import { useSetRecoilState } from 'recoil'
import { EnabledChat } from '../stores/AppStore'
import { ChatTypeEnum } from '../enums/ChatTypeEnum'

const WinwiseOpportunity: FC = () => {
  const appInsights = appInsightsInstance()
  if (appInsights) appInsights.trackPageView({ name: 'Winwise Opportunity' })

  const { opportunityId } = useParams()
  const navigate = useNavigate()

  const [isLoading, setIsLoading] = useState(false)
  const [opportunity, setOpportunity] = useState<IWinwiseOpportunityDetailModel | null>(null)
  const [isShowingCreateNewThreadPopup, setIsShowingCreateNewThreadPopup] = useState(false)
  const [isOnThread, setIsOnThread] = useState(false)
  const setEnabledChat = useSetRecoilState(EnabledChat)

  const fetchOpportunity = useCallback(async () => {
    if (!opportunityId) return
    setIsLoading(true)
    const response = await getWinwiseOpportunityDetail({ opportunityId: opportunityId ?? '' })
    const opportunity = response?.data ?? null

    setOpportunity(opportunity)
    setIsOnThread(!!opportunity?.uploadedFiles.length || !!opportunity?.handyLinks)
    setIsLoading(false)
    setEnabledChat(!opportunity?.archived)
  }, [opportunityId, setEnabledChat])

  useEffect(() => {
    fetchOpportunity()

    return () => setEnabledChat(true)
  }, [fetchOpportunity, setEnabledChat])

  const goBack = () => navigate(`/${AppRoute.WINWISE}`)

  const onUploadFileChange = (file: IWinwiseOpportunityUploadedFile) => {
    setOpportunity((prevOpportunity: IWinwiseOpportunityDetailModel | null) => {
      if (!prevOpportunity) return null

      const newFiles = prevOpportunity.uploadedFiles.filter((f) => f.id !== file.id)
      return { ...prevOpportunity, uploadedFiles: [...newFiles, file] }
    })
  }

  const onRemoveFileChange = (file: IWinwiseOpportunityUploadedFile) => {
    setOpportunity((prevOpportunity: IWinwiseOpportunityDetailModel | null) => {
      if (!prevOpportunity) return null

      const newFiles = prevOpportunity.uploadedFiles.filter((f) => f.id !== file.id)
      return { ...prevOpportunity, uploadedFiles: newFiles }
    })
  }

  const renderMainContent = () => {
    if (!opportunity) return <></>
    if (isOnThread)
      return (
        <WinwiseOpportunityThread
          opportunity={opportunity}
          goBack={goBack}
          onCreateNewThread={() => setIsShowingCreateNewThreadPopup(true)}
          onOpportunityChange={setOpportunity}
        />
      )
    return (
      <WinwiseOpportunityFileUpload
        opportunity={opportunity}
        goBack={goBack}
        onCreateNewThread={() => setIsShowingCreateNewThreadPopup(true)}
        onOpportunityChange={setOpportunity}
        onUploadFileChange={onUploadFileChange}
        onRemoveFileChange={onRemoveFileChange}
      />
    )
  }

  return (
    <Page
      menu
      contentWrapper
      contentWrapperWide
      cssClassName={classnames({ [Style.winwiseOpportunityWrapper]: isOnThread })}
    >
      {isLoading ? <LoadingScreen chatType={ChatTypeEnum.WINWISE} /> : renderMainContent()}
      {isShowingCreateNewThreadPopup && !opportunity?.archived && (
        <CreateNewThreadModal
          isShowing={isShowingCreateNewThreadPopup}
          opportunityId={opportunity?.id ?? ''}
          onClose={() => setIsShowingCreateNewThreadPopup(false)}
          onCreateThread={() => {
            setIsOnThread(true)
            setIsShowingCreateNewThreadPopup(false)
            setEnabledChat(true)
          }}
        />
      )}
    </Page>
  )
}

export default WinwiseOpportunity
