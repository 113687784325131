import { FC } from 'react'
import Style from '../../styles/modal/CreateNewThreadModal.module.sass'
import RecallButton from '../common/RecallButton'
import { ChatTypeEnum } from '../../enums/ChatTypeEnum'
import RecallModal from '../common/RecallModal'
import { useSetRecoilState } from 'recoil'
import { ChatSession, InShareChat } from '../../stores/AppStore'
import { useNavigate, useParams } from 'react-router-dom'
import { AppRoute } from '../../enums/AppRouteConstants'
import { useLanguages } from '../../hooks/useLanguages'

interface ICreateNewThreadModalProps {
  isShowing: boolean
  opportunityId: string
  onClose: () => void
  onCreateThread: () => void
}

const CreateNewThreadModal: FC<ICreateNewThreadModalProps> = (props) => {
  const { isShowing, onClose, onCreateThread, opportunityId } = props
  const { chatId } = useParams()
  const navigate = useNavigate()
  const { t } = useLanguages()

  const setInShareChat = useSetRecoilState(InShareChat)
  const setChatSession = useSetRecoilState(ChatSession)

  const handleCreateNewThread = (inShare: boolean) => {
    setInShareChat(inShare)
    setChatSession(null)
    onCreateThread()

    if (chatId) {
      navigate(`/${AppRoute.WINWISE_THREAD}/${opportunityId}`, { replace: true })
    }
  }

  return (
    <RecallModal
      chatType={ChatTypeEnum.WINWISE}
      size='small'
      disabled={false}
      cssClass={Style.createNewThreadModal}
      isShowing={isShowing}
      showActionButtons={false}
      onClose={onClose}
    >
      <h2>{t('create_thread')}</h2>
      <p>{t('create_thread_desc')}</p>

      <div className={Style.actionButtons}>
        <RecallButton
          type='primary'
          chatType={ChatTypeEnum.WINWISE}
          label={t('share_thread')}
          onClick={() => handleCreateNewThread(true)}
        />
        <span className={Style.or}>{t('or')}</span>
        <RecallButton
          type='primary'
          chatType={ChatTypeEnum.WINWISE}
          label={t('personal_thread')}
          onClick={() => handleCreateNewThread(false)}
        />
      </div>
    </RecallModal>
  )
}

export default CreateNewThreadModal
