import { FC } from 'react'
import { Loader } from '@aurecon-creative-technologies/styleguide'

import Style from '../styles/LoadingScreen.module.sass'
import classNames from 'classnames'
import { ChatTypeEnum } from '../enums/ChatTypeEnum'

interface ILoadingScreenProps {
  text?: string
  size?: 'small' | 'extra small' | 'medium' | 'large' | 'extra large'
  chatType?: number
  hasLabel?: boolean
  cssClass?: string
}

const LoadingScreen: FC<ILoadingScreenProps> = (props) => {
  const { chatType, hasLabel } = props

  const chatTypeClasses = () => {
    return classNames({
      [Style.winwise]: chatType === ChatTypeEnum.WINWISE,
    })
  }

  return (
    <div className={classNames(Style.loader, props.cssClass)}>
      <Loader
        label={hasLabel ?? props.text ? 'Loading...' : undefined}
        size={props.size}
        cssClass={chatTypeClasses()}
      />
    </div>
  )
}

export default LoadingScreen
