import { FC, useEffect, useMemo, useState } from 'react'
import Style from '../../styles/components/winwise/WinwiseOpportunityFileUpload.module.sass'
import {
  IDownloadWinwiseOpportunityFileRequestModel,
  IWinwiseOpportunityDetailModel,
  IWinwiseOpportunityUploadedFile,
} from '../../models/IWinwiseOpportunity'
import {
  Accordion,
  AccordionPanel,
  FileUpload,
  Icon,
  InfoTooltip,
  IOverflowMenuItemProps,
  Loader,
  OverflowMenu,
  sizeUnits,
  Table,
  TableCell,
  TableRow,
} from '@aurecon-creative-technologies/styleguide'
import {
  WinwiseOpportunityUploadFileStatusEnum,
  WinwiseOpportunityDocumentTypeEnum,
  WinwiseOpportunityDocumentOptions,
} from '../../enums/WinwiseOpportunityEnum'
import { useLanguages } from '../../hooks/useLanguages'
import { FileMimeType } from '../../enums/FileTypeEnum'
import { useAuth0 } from '@auth0/auth0-react'
import { v4 } from 'uuid'
import {
  uploadWinwiseOpportunityFile,
  updateWinwiseOpportunity,
  deleteWinwiseOppoturnityFile,
  downloadWinwiseOppoturnityFile,
} from '../../api/winwiseService'
import {
  FILE_SIZE_LIMIT,
  twelveHourFormat,
  WINWISE_ALLOWED_FILE_TYPES,
  WINWISE_TOTAL_FILE_SIZE_LIMIT,
} from '../../config/config'
import { urlValidation } from '../../enums/RegexConst'
import { SortEnum, SortType } from '../../enums/SortType'
import { sortArrBy } from '../../helpers/utils'
import { usefileDownload } from '../../hooks/useFileDownload'
import { ResponseData } from '../../models/api/IResponse'
import RecallFileTypeIcon from '../common/RecallFileTypeIcon'
import RecallFormInput from '../common/RecallFormInput'
import { ChatTypeEnum } from '../../enums/ChatTypeEnum'
import RecallButton from '../common/RecallButton'
import { format, parseISO } from 'date-fns'
import { fileSize } from '../../helpers/fileUtils'
import { Trans } from 'react-i18next'
import { AppRoute } from '../../enums/AppRouteConstants'

interface IFileTableSortOrder {
  name: SortType
  lastModified: SortType
  uploadedBy: SortType
}

const defaultSortOrder: IFileTableSortOrder = {
  name: SortEnum.None,
  lastModified: SortEnum.None,
  uploadedBy: SortEnum.None,
}

const columnWidth = {
  name: '50%',
  lastModified: '25%',
  uploadedBy: '20%',
  action: '5%',
}

const ContextMenuItemIds = {
  open: '1',
  download: '2',
  delete: '3',
}

const getDefaultHandyLink = () => ({
  id: v4(),
  value: '',
  error: '',
})

const maxSizeInBytes = WINWISE_TOTAL_FILE_SIZE_LIMIT * 1024 * 1024 // 100MB in bytes
const minimumHandyLinks = 3
const defaultHandyLinks = Array.from({ length: minimumHandyLinks }).map(() => getDefaultHandyLink())

interface IWinwiseOpportunityFileUploadProps {
  opportunity: IWinwiseOpportunityDetailModel
  goBack: () => void
  onCreateNewThread: () => void
  onOpportunityChange: (opportunity: IWinwiseOpportunityDetailModel) => void
  onUploadFileChange: (opportunityUploadFile: IWinwiseOpportunityUploadedFile) => void
  onRemoveFileChange: (opportunityUploadFile: IWinwiseOpportunityUploadedFile) => void
}

const WinwiseOpportunityFileUpload: FC<IWinwiseOpportunityFileUploadProps> = (props) => {
  const { opportunity, goBack, onCreateNewThread, onOpportunityChange, onUploadFileChange, onRemoveFileChange } = props
  const { t } = useLanguages()

  const { user } = useAuth0()
  const { downloadFile } = usefileDownload()

  const [sortOrder, setSortOrder] = useState<IFileTableSortOrder>(defaultSortOrder)
  const [selectedPanelIds, setSelectedPanelIds] = useState<string[]>([])
  const [currentHandyLinks, setCurrentHandyLinks] = useState(defaultHandyLinks)
  const [resetKey, setResetKey] = useState(0)
  const [selectedPdfFilename, setSelectedPdfFilename] = useState('')

  const sharedContextMenu: IOverflowMenuItemProps[] = [
    { id: ContextMenuItemIds.open, label: t('open') },
    { id: ContextMenuItemIds.download, label: t('download') },
    { id: ContextMenuItemIds.delete, label: t('delete') },
  ]

  useEffect(() => {
    const closeTabHandler = (e: Event) => {
      e.stopPropagation()
      e.preventDefault()
      localStorage.removeItem(selectedPdfFilename)
    }
    window.addEventListener('unload', closeTabHandler)
    return () => {
      window.removeEventListener('unload', closeTabHandler)
      localStorage.removeItem(selectedPdfFilename)
    }
  }, [selectedPdfFilename])

  const onUploadFile = async (files: File[], fileTypeId: number) => {
    if (!files.length || opportunity.archived) return

    const file = files[0]

    const date = new Date()
    const isoString = date.toISOString()

    const totalSize =
      opportunity.uploadedFiles
        .filter((f) => f.fileTypeId === fileTypeId)
        .reduce((accum, file) => accum + file.size, 0) + file.size
    const isOverLimit = totalSize > maxSizeInBytes

    const selectedFile: IWinwiseOpportunityUploadedFile = {
      id: v4(),
      contentType: file.type,
      lastModified: isoString,
      name: file.name,
      size: file.size,
      uploadedByUserDisplayName: user?.name ?? '',
      fileTypeId: fileTypeId,
      status: isOverLimit
        ? WinwiseOpportunityUploadFileStatusEnum.FAIL
        : WinwiseOpportunityUploadFileStatusEnum.LOADING,
      error: isOverLimit ? t('upload_total_file_exceed', { maxSize: fileSize(maxSizeInBytes) }) : '',
    }

    let newFileName = file.name
    let suffixNumber = 1
    while (opportunity.uploadedFiles.some((f) => f.name === newFileName)) {
      const extensionIndex = file.name.lastIndexOf('.')
      const baseName = extensionIndex === -1 ? file.name : file.name.slice(0, extensionIndex)
      const extension = extensionIndex === -1 ? '' : file.name.slice(extensionIndex)
      newFileName = `${baseName}(${suffixNumber++})${extension}`
    }

    selectedFile.name = newFileName
    const updatedFile = new File([file], newFileName)

    onUploadFileChange(selectedFile)
    if (isOverLimit) {
      onClearUploaderState()
      return
    }

    const requestBody = {
      opportunityId: opportunity.id,
      opportunityFileTypeId: fileTypeId.toString(),
      file: updatedFile,
    }
    const res = await uploadWinwiseOpportunityFile(requestBody)
    const isSuccess = res?.success ?? false
    updateFileStatus(selectedFile, isSuccess)
  }

  const updateFileStatus = (selectedFile: IWinwiseOpportunityUploadedFile, isUploadedSuccess: boolean) => {
    const newFile = { ...selectedFile }

    newFile.error = isUploadedSuccess ? '' : t('error_uploading')
    newFile.status = isUploadedSuccess
      ? WinwiseOpportunityUploadFileStatusEnum.SUCCESS
      : WinwiseOpportunityUploadFileStatusEnum.FAIL

    onUploadFileChange(newFile)
    onClearUploaderState()
  }

  const onUpdateWinWiseOpportunity = async () => {
    const validHandyLinks = currentHandyLinks.filter((f) => f.value && !f.error)
    if (!validHandyLinks.length) return

    const handyLinks = JSON.stringify(validHandyLinks.map((m) => m.value))
    const requestBody = {
      id: opportunity?.id ?? '',
      handyLinks: handyLinks,
    }

    onOpportunityChange({ ...opportunity, handyLinks })
    await updateWinwiseOpportunity(requestBody)
  }

  const onClearUploaderState = () => {
    setResetKey(resetKey + 1)
  }

  const togglePanel = (panelId: string) => {
    const isExisting = selectedPanelIds.includes(panelId)
    const panelIds = isExisting ? selectedPanelIds.filter((f) => f !== panelId) : [...selectedPanelIds, panelId]

    setSelectedPanelIds(panelIds)
  }

  const onSort = (field: string, sort: string) => {
    const newOrder = {
      ...defaultSortOrder,
      [field]: sort,
    }
    setSortOrder(newOrder)
  }

  const sortedList = useMemo(() => {
    const newUploadedFiles = opportunity?.uploadedFiles || []

    const foundSortKey = (Object.keys(sortOrder) as [keyof IFileTableSortOrder]).find(
      (key) => sortOrder[key] === SortEnum.Asc || sortOrder[key] === SortEnum.Desc,
    )

    const sortKey = foundSortKey ?? 'lastModified'
    const order = sortOrder[sortKey]

    return sortArrBy(order as string, newUploadedFiles, sortKey) as IWinwiseOpportunityUploadedFile[]
  }, [opportunity?.uploadedFiles, sortOrder])

  const onHandyLinkChange = (value: string, id: string) => {
    const newHandyLinks = [...currentHandyLinks].map((link) => {
      if (id !== link.id) return link
      const isValidurl = urlValidation.test(value)
      const error = isValidurl ? '' : 'invalid URL'

      return {
        ...link,
        value: value,
        error: value ? error : '',
      }
    })

    setCurrentHandyLinks(newHandyLinks)
  }

  const onAddNewHandyLink = () => {
    const newHandyLink = getDefaultHandyLink()
    setCurrentHandyLinks([...currentHandyLinks, newHandyLink])
  }

  const onSelectFileAction = async (action: IOverflowMenuItemProps, file: IWinwiseOpportunityUploadedFile) => {
    if (!opportunity) return

    const req = {
      opportunityId: opportunity.id,
      filename: file.name,
      fileTypeId: file.fileTypeId,
    }

    switch (action.id) {
      case ContextMenuItemIds.download:
        await handleDownload(req, file)
        break
      case ContextMenuItemIds.delete:
        await handleDelete(req, file)
        break
      case ContextMenuItemIds.open:
        await handleOpen(req, file)
        break
      default:
        break
    }
  }

  const handleDownload = async (
    request: IDownloadWinwiseOpportunityFileRequestModel,
    file: IWinwiseOpportunityUploadedFile,
  ) => {
    const blob = await fetchFile(request)
    if (blob) {
      downloadFile(blob, file.name)
    }
  }

  const handleDelete = async (
    request: IDownloadWinwiseOpportunityFileRequestModel,
    file: IWinwiseOpportunityUploadedFile,
  ) => {
    const res = await deleteWinwiseOppoturnityFile(request)
    if (res?.success) {
      onRemoveFileChange(file)
    }
  }

  const onRemoveErrorFile = (file: IWinwiseOpportunityUploadedFile) => {
    onRemoveFileChange(file)
  }

  const handleOpen = async (
    request: IDownloadWinwiseOpportunityFileRequestModel,
    file: IWinwiseOpportunityUploadedFile,
  ) => {
    const blob = await fetchFile(request)
    if (blob && file.contentType === FileMimeType.PDF) {
      const pdfFile = new File([blob], file.name, { type: FileMimeType.PDF })

      selectedPdfFilename && localStorage.removeItem(selectedPdfFilename)

      setSelectedPdfFilename(file.name)
      localStorage.setItem(file.name, URL.createObjectURL(pdfFile))
      window.open(`#/${AppRoute.PDF_VIEWER}/${file.name}`, '_blank')
    }
  }

  const fetchFile = async (request: IDownloadWinwiseOpportunityFileRequestModel) => {
    const response = await downloadWinwiseOppoturnityFile(request)
    return ResponseData(response)
  }

  const renderHandyLinks = () => {
    return (
      <div className={Style.handyLinksWrap}>
        <div className={Style.label}>URL</div>

        {currentHandyLinks.map((link) => {
          const { id, value, error } = link

          return (
            <RecallFormInput
              chatType={ChatTypeEnum.WINWISE}
              key={id}
              cssClass={Style.urlInput}
              placeholder='https://www.google.com'
              value={value}
              error={error}
              onChange={(value) => onHandyLinkChange(value, id)}
            />
          )
        })}

        <div className={Style.addHandyLinkWrap}>
          <Icon size='32px' type='add_circle' onClick={onAddNewHandyLink} className={Style.icon} />
        </div>
      </div>
    )
  }

  const renderUploadFile = (fileTypeId: number) => {
    const supportFileMessage = `${t('upload_title2_tolltip')} ${WINWISE_ALLOWED_FILE_TYPES.join(', ')}`

    return (
      <FileUpload
        key={resetKey}
        allowedFileExtensions={WINWISE_ALLOWED_FILE_TYPES}
        allowedFileExtensionsMessage={supportFileMessage}
        sizeLimit={[FILE_SIZE_LIMIT, sizeUnits.MegaByte]}
        sizeLimitMessage={`The file size exceeds ${FILE_SIZE_LIMIT} ${sizeUnits.MegaByte.label}`}
        dropzoneIcon={<Icon size='40px' type='upload_file' />}
        dropzoneLabel={<Trans i18nKey='upload_title' components={[<span key={0} className='browse' />]} />}
        height='200px'
        onChange={(files: File[]) => onUploadFile(files, fileTypeId)}
        showDeleteButton
        dropzoneTooltip={
          <span className='tooltip'>
            <InfoTooltip
              show={
                <span style={{ marginRight: '6px', textAlign: 'left' }}>
                  <div>{t('upload_title2_tolltip')}</div>
                  <div>{WINWISE_ALLOWED_FILE_TYPES.join(', ')}</div>
                </span>
              }
            />
            {`${supportFileMessage} - (max ${WINWISE_TOTAL_FILE_SIZE_LIMIT}mb - XXX files)`}
          </span>
        }
      />
    )
  }

  const renderLastModyfied = (file: IWinwiseOpportunityUploadedFile) => {
    const isUploadedFail = file.status === WinwiseOpportunityUploadFileStatusEnum.FAIL
    const isUploading = file.status === WinwiseOpportunityUploadFileStatusEnum.LOADING

    return (
      <div className={Style.lastModifiedWrap}>
        {isUploading ? (
          <div className={Style.fileUploading}>
            <Loader size='extra small' />
            <span>{`${t('uploading')}...`}</span>
          </div>
        ) : (
          <>
            <Icon
              size='24px'
              type={isUploadedFail ? 'error' : 'check_circle'}
              className={isUploadedFail ? Style.errorIcon : Style.successIcon}
            />
            <span>{isUploadedFail ? file.error : format(parseISO(file.lastModified), twelveHourFormat)}</span>
          </>
        )}
      </div>
    )
  }

  const renderFiles = (fileTypeId: number) => {
    const validFiles = sortedList?.filter((f) => f.fileTypeId === fileTypeId) || []
    const totalSize = validFiles.reduce((accum, file) => accum + file.size, 0)

    if (!validFiles.length) return <></>

    return (
      <div className={Style.fileTableWrap}>
        <Table
          headers={[
            {
              label: t('filename'),
              sort: sortOrder.name,
              onSort: (sort) => onSort('name', sort),
              style: { width: columnWidth.name },
            },
            {
              label: t('last_modify'),
              sort: sortOrder.lastModified,
              onSort: (sort) => onSort('lastModified', sort),
              style: { width: columnWidth.lastModified },
            },
            {
              label: t('uploaded_by'),
              sort: sortOrder.uploadedBy,
              onSort: (sort) => onSort('uploadedBy', sort),
              style: { width: columnWidth.uploadedBy },
            },
            { label: t('action'), style: { width: columnWidth.action } },
          ]}
        >
          {validFiles.map((file) => {
            const isPdfFile = file.contentType === FileMimeType.PDF
            const isErrorFile = file?.status === WinwiseOpportunityUploadFileStatusEnum.FAIL

            const contextMenu = isPdfFile
              ? sharedContextMenu
              : sharedContextMenu.filter((f) => f.id !== ContextMenuItemIds.open)

            return (
              <TableRow key={file.id} rowClass={Style.tableRow}>
                <TableCell
                  title={file.name}
                  cellClass={Style.cell}
                  style={{
                    width: columnWidth.name,
                  }}
                >
                  <div className={Style.filenameWrap}>
                    <RecallFileTypeIcon fileMimeType={file.contentType} />
                    <span>{file.name}</span>
                  </div>
                </TableCell>
                <TableCell
                  cellClass={Style.cell}
                  style={{
                    width: columnWidth.lastModified,
                  }}
                >
                  {renderLastModyfied(file)}
                </TableCell>
                <TableCell
                  cellClass={Style.date}
                  style={{
                    width: columnWidth.uploadedBy,
                  }}
                >
                  {file.uploadedByUserDisplayName}
                </TableCell>
                <TableCell cellClass={Style.action} style={{ width: columnWidth.action }}>
                  <div
                    role='none'
                    className={Style.actionWrapper}
                    onClick={(e) => {
                      e.preventDefault()
                      e.stopPropagation()
                    }}
                  >
                    {isErrorFile ? (
                      <Icon
                        size='24px'
                        outlined
                        type='delete'
                        className={Style.deleteIcon}
                        onClick={() => onRemoveErrorFile(file)}
                      />
                    ) : (
                      <OverflowMenu
                        items={contextMenu}
                        size='extra small'
                        icon='more_vert'
                        default
                        cssClass='winwise-overflow-menu'
                        onSelectItem={(item) => onSelectFileAction(item, file)}
                      />
                    )}
                  </div>
                </TableCell>
              </TableRow>
            )
          })}
        </Table>

        <div className={Style.totalFileSize}>{`${validFiles.length} files, total of (${fileSize(totalSize)})`}</div>
      </div>
    )
  }

  const renderOpportunityUploadOptions = () => {
    return (
      <div className={Style.uploadOptionWrapper}>
        <Accordion activePanelIds={selectedPanelIds} cssClass={Style.accordionWrap} onPanelToggle={togglePanel}>
          {WinwiseOpportunityDocumentOptions.map((uploadOption) => {
            const { label, id } = uploadOption

            return (
              <AccordionPanel key={id} panelId={id.toString()} label={label}>
                {id === WinwiseOpportunityDocumentTypeEnum.HANDY_LINKS ? (
                  renderHandyLinks()
                ) : (
                  <>
                    {renderUploadFile(id)}
                    {renderFiles(id)}
                  </>
                )}
              </AccordionPanel>
            )
          })}
        </Accordion>
      </div>
    )
  }

  const renderActionButtons = () => {
    const isUpdatedOppotunity = opportunity?.uploadedFiles.length || currentHandyLinks.filter((f) => f.value).length
    const hasNoFileError = opportunity?.uploadedFiles.every((f) => !f.error)
    const hasNoHandyLinkError = currentHandyLinks.every((f) => !f.error)
    const isEnable = isUpdatedOppotunity && hasNoFileError && hasNoHandyLinkError

    return (
      <div className={Style.actionButtons}>
        <RecallButton
          chatType={ChatTypeEnum.WINWISE}
          label={t('next')}
          type='primary'
          disabled={!isEnable || opportunity.archived}
          onClick={() => {
            onCreateNewThread()
            onUpdateWinWiseOpportunity()
          }}
        />
      </div>
    )
  }

  return (
    <div className={Style.opportunityFileUploadWrap}>
      <div className={Style.headerWrapper}>
        <RecallButton
          type='text'
          chatType={ChatTypeEnum.WINWISE}
          size='small'
          icon='arrow_back_ios'
          label={t('go_back')}
          cssClass={Style.goBackBtn}
          onClick={goBack}
        />

        <h2>
          {opportunity?.clientName} - {opportunity?.name}
        </h2>
      </div>

      <div className={Style.descriptionWrap}>
        <h3>{t('upload_files')}</h3>
        <span>{t('upload_files_desc')}</span>
      </div>

      {renderOpportunityUploadOptions()}
      {renderActionButtons()}
    </div>
  )
}

export default WinwiseOpportunityFileUpload
