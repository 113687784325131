import { FC, useState } from 'react'
import Style from '../../styles/components/winwise/WinwiseOpportunityChatSubscribe.module.sass'
import LoadingScreen from '../LoadingScreen'
import { ChatTypeEnum } from '../../enums/ChatTypeEnum'
import RecallButton from '../common/RecallButton'
import { Icon } from '@aurecon-creative-technologies/styleguide'
import { subscribeWinwiseOpportunityChat } from '../../api/winwiseService'
import { useLanguages } from '../../hooks/useLanguages'

interface IWinwiseOpportunityChatSubscribeProps {
  opportunityId: string
  chatId: string
  subcribed: boolean
}

const WinwiseOpportunityChatSubscribe: FC<IWinwiseOpportunityChatSubscribeProps> = (props) => {
  const { opportunityId, chatId, subcribed } = props
  const [isSubscribed, setIsSubscribed] = useState(subcribed)

  const { t } = useLanguages()

  const onSubscribeChat = async () => {
    const requestBody = {
      chatId,
      opportunityId,
    }

    const response = await subscribeWinwiseOpportunityChat(requestBody)
    setIsSubscribed(!!response?.data)
  }

  return (
    <div className={Style.chatSubscribeContainer}>
      <div>
        <LoadingScreen cssClass={Style.loadingResponse} hasLabel={false} chatType={ChatTypeEnum.WINWISE} />
        <div className={Style.waitForTheResponse}>
          <p className={Style.subTitle}>{t('concurrent_question')}</p>
          <p className={Style.estimatedText}>{t('concurrent_question1')}</p>
        </div>
      </div>

      <div className={Style.subscribeWrap}>
        <Icon size='32px' outlined type='mark_email_unread' />
        <p className={Style.description}>{t('notified_email')}</p>

        {isSubscribed ? (
          <div className={Style.message}>{t('subscribed_success')}</div>
        ) : (
          <RecallButton
            chatType={ChatTypeEnum.WINWISE}
            type='primary'
            label={t('subscribe')}
            onClick={onSubscribeChat}
          />
        )}
      </div>
    </div>
  )
}

export default WinwiseOpportunityChatSubscribe
