import { FC, useEffect, useMemo, useRef, useState } from 'react'
import { Button, FormInput } from '@aurecon-creative-technologies/styleguide'
import { useMediaQuery } from 'react-responsive'
import classNames from 'classnames'

import { ChatSession, EnabledChat, FocusOnInput, QuestionInput, WinwiseSelectedQueries } from '../stores/AppStore'
import { useRecoilState, useRecoilValue } from 'recoil'
import { ChatTypeEnum, ChatTypeToPrompt } from '../enums/ChatTypeEnum'
import { UI_WIDTH_COLLAPSE } from '../config/config'
import { useLanguages } from '../hooks/useLanguages'

import Style from '../styles/SubmitButton.module.sass'
import { useNomicToken } from '../hooks/useNomicToken'
import { useSubmitButton } from '../hooks/useSubmitButton'
import { useAppConfig } from '../hooks/useAppConfig'
import { ICreateChatResponseModel } from '../models/api/ICreateChatModels'

const ENTER_KEY = 'Enter'
const HISTORY_START = 0

interface ISubmitButtonProps {
  chatType: number
  fileUploadRef: React.RefObject<HTMLInputElement>
  imageUploadRef: React.RefObject<HTMLInputElement>
  createNewChatCallback?: (chat: ICreateChatResponseModel) => void
}

const SubmitButton: FC<ISubmitButtonProps> = (props) => {
  const { chatType, fileUploadRef, imageUploadRef, createNewChatCallback } = props

  const [shift, setShift] = useState(false)
  const [history, setHistory] = useState(0)

  const focusOnInput = useRecoilState(FocusOnInput)
  const [questionInput, setQuestionInput] = useRecoilState(QuestionInput)
  const chatSession = useRecoilValue(ChatSession)
  const enabledChat = useRecoilValue(EnabledChat)
  const winwiseSelectedQueries = useRecoilValue(WinwiseSelectedQueries)

  const { t } = useLanguages()

  const config = useAppConfig()

  const { nomicToken } = useNomicToken(chatType)

  const textInputRef = useRef<HTMLInputElement>(null)

  const { handleQuestionSubmit, stopGenerating } = useSubmitButton(
    chatType,
    fileUploadRef,
    imageUploadRef,
    createNewChatCallback,
  )

  const isDesktop = useMediaQuery({ minWidth: UI_WIDTH_COLLAPSE })

  useEffect(() => {
    if (!chatSession) return
    setHistory(chatSession.questions.length - 1)
  }, [chatSession])

  useEffect(() => {
    setTimeout(() => textInputRef.current?.focus(), 0)
  }, [focusOnInput])

  useEffect(() => {
    if (!textInputRef.current) return

    const keyDownEvent = (event: KeyboardEvent) => {
      if (event.key === ENTER_KEY && !event.shiftKey) event.preventDefault()

      setShift(event.shiftKey)

      if (!chatSession) return

      if (event.key === 'ArrowUp' && event.shiftKey) {
        event.preventDefault()
        const newHistory = history === HISTORY_START ? HISTORY_START : history - 1

        const question = chatSession.questions[newHistory].question
        setQuestionInput(question)
        setHistory(newHistory)
      }

      if (event.key === 'ArrowDown' && event.shiftKey) {
        event.preventDefault()
        const valid = history < chatSession.questions.length - 1
        const newHistory = history + 1

        const max = newHistory > chatSession.questions.length
        setHistory(max ? history : newHistory)

        if (valid) {
          const question = chatSession.questions[newHistory].question
          setQuestionInput(question)
          return
        }

        setQuestionInput('')
      }
    }

    const keyUpEvent = (event: KeyboardEvent) => {
      setShift(event.shiftKey)
    }

    const el = textInputRef.current
    el.addEventListener('keydown', keyDownEvent)
    el.addEventListener('keyup', keyUpEvent)

    return () => {
      el.removeEventListener('keydown', keyDownEvent)
      el.removeEventListener('keyup', keyUpEvent)
    }
  }, [chatSession, history, setQuestionInput])

  useEffect(() => {
    if (!textInputRef.current) return

    textInputRef.current.style.height = `0px`
    const scrollHeight = textInputRef.current.scrollHeight
    textInputRef.current.style.height = `${scrollHeight + 2}px`
  }, [questionInput])

  const loading = chatSession?.questions.some((q) => !!q.loading)
  const homePage = !chatSession?.questions.length
  const prompts = ChatTypeToPrompt[chatType]

  const placeholderText = homePage ? t(prompts.start) : t(prompts.follow)
  const placeholder = loading && chatType === chatSession?.type ? t(prompts.loading) : placeholderText

  const submitButtonProps = isDesktop
    ? { type: 'primary' as 'icon-square' | 'primary', label: loading ? t('stop') : t('submit') }
    : { type: 'icon-square' as 'icon-square' | 'primary', icon: loading ? 'stop' : 'send' }

  const submitDisabled = useMemo(() => {
    if (chatType === ChatTypeEnum.WINWISE) {
      return !winwiseSelectedQueries.length && !questionInput
    }

    // all other apps
    return (chatType === ChatTypeEnum.CUSTOM_RECALL_APP && !nomicToken) || (!questionInput && !loading) || !enabledChat
  }, [chatType, enabledChat, loading, nomicToken, questionInput, winwiseSelectedQueries.length])

  const submitButtonClasses = classNames({
    [Style.submitButton]: true,
    [Style.chatGpt]: chatType === ChatTypeEnum.GPT,
    [Style.chatRecall]: chatType === ChatTypeEnum.RECALL,
    [Style.cra]: chatType === ChatTypeEnum.CUSTOM_RECALL_APP,
    [Style.bhp]: chatType === ChatTypeEnum.BHP_STANDARDS,
    [Style.rioTinto]: chatType === ChatTypeEnum.RIO_TINTO_STANDARDS,
    [Style.ausNet]: chatType === ChatTypeEnum.AUSNET_STANDARDS,
    [Style.pmApp]: chatType === ChatTypeEnum.PM_APP,
    [Style.winwise]: chatType === ChatTypeEnum.WINWISE,
  })

  const handleSubmitQuestion = async (key: string) => {
    if ((key && key !== ENTER_KEY) || shift) return

    const newChatSession = await handleQuestionSubmit()

    if (newChatSession) setHistory(newChatSession.questions.length + 1)
  }

  return (
    <>
      <FormInput
        placeholder={placeholder}
        cssClass={Style.input}
        value={questionInput}
        onChange={setQuestionInput}
        disabled={loading || !enabledChat}
        onKeyDown={handleSubmitQuestion}
        multiline
        multilineLimit={config?.PROMPT_LENGTH_LIMIT}
        type='multiline'
        ref={textInputRef}
      />
      <span>
        <Button
          {...submitButtonProps}
          cssClass={submitButtonClasses}
          onClick={loading ? stopGenerating : () => handleQuestionSubmit()}
          disabled={submitDisabled}
        />
      </span>
    </>
  )
}

export default SubmitButton
